import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ResetAuthErrors, sendForgotPassword, sendForgotPasswordReset } from '../../redux/authentication/actionCreator';
import { Link } from 'react-router-dom';
import FormError from "../../components/Form/FormError";
import FormItem from '../../components/Form/FormItem';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const isLoading = useSelector(state => state.auth.loading);
    const forgotPasswordError = useSelector(state => state.auth.error);
    const forgotPasswordDone = useSelector(state => state.auth.done);
    const forgotPasswordValidationFields = useSelector(state => state.auth.fields);
    const lang = useSelector(state => state.settings.lang);

    // Define translations for English and French
    const translations = {
        en: {
            checkInboxHeader: "Check your Inbox",
            checkInboxContent: "We have e-mailed your password reset link! Check your spam folder to make sure it didn't end up there.",
            signIn: "Sign in",
            forgotPasswordHeader: "Forgot Password?",
            instructionsText: "Enter the email address you used when you joined and we’ll send you instructions to reset your password.",
            emailLabel: "Email",
            emailPlaceholder: "name@example.com",
            sendResetInstructions: "Send Reset Instructions",
            returnText: "Return to"
        },
        fr: {
            checkInboxHeader: "Vérifiez votre boîte de réception",
            checkInboxContent: "Nous vous avons envoyé par e-mail le lien de réinitialisation de votre mot de passe ! Vérifiez votre dossier spam pour vous assurer qu'il n'y est pas par erreur.",
            signIn: "Se connecter",
            forgotPasswordHeader: "Mot de passe oublié ?",
            instructionsText: "Entrez l'adresse e-mail que vous avez utilisée lors de votre inscription et nous vous enverrons des instructions pour réinitialiser votre mot de passe.",
            emailLabel: "Email",
            emailPlaceholder: "name@example.com",
            sendResetInstructions: "Envoyer les instructions de réinitialisation",
            returnText: "Retour à"
        }
    };

    // Use French if lang is 'fr', otherwise default to English.
    const currentLang = lang === 'fr' ? 'fr' : 'en';

    const handleSubmit = () => {
        dispatch(sendForgotPassword(form.getFieldsValue()));
    };

    useEffect(() => {
        dispatch(sendForgotPasswordReset());
        dispatch(ResetAuthErrors());
    }, [dispatch]);

    return (
        <div className="auth-contents flex flex-col items-center justify-center mt-4 py-2 px-4 lg:py-4 lg:px-8 uppercase">
            <div className="max-w-xl w-full">
                {forgotPasswordDone ? (
                    <div className="flex flex-col">
                        <div>
                            <h1 className="text-2xl font-bold">{translations[currentLang].checkInboxHeader}</h1>
                            {translations[currentLang].checkInboxContent}
                        </div>
                        <Link to="/login" className="text-lg mt-3">
                            {translations[currentLang].signIn}
                        </Link>
                    </div>
                ) : (
                    <Form name="forgotPassword" form={form} onFinish={handleSubmit} layout="vertical">
                        <div className="text-2xl font-bold">{translations[currentLang].forgotPasswordHeader}</div>

                        <FormError error={forgotPasswordError} />

                        <p className="forgot-text mt-2">
                            {translations[currentLang].instructionsText}
                        </p>
                        <FormItem
                            name="email"
                            label={translations[currentLang].emailLabel}
                            errors={forgotPasswordValidationFields}
                            disabled={isLoading}
                            initialValue=""
                        >
                            <Input placeholder={translations[currentLang].emailPlaceholder} disabled={isLoading} className="h-10" />
                        </FormItem>

                        <Button
                            htmlType="submit"
                            size="large"
                            type="primary"
                            key="submit"
                            loading={isLoading}
                            className="mt-3 uppercase"
                        >
                            {translations[currentLang].sendResetInstructions}
                        </Button>

                        <div className="return-text mt-5 text-right uppercase">
                            {translations[currentLang].returnText} <Link to="/login" className="underline">{translations[currentLang].signIn}</Link>
                        </div>
                    </Form>
                )}
            </div>
        </div>
    );
};

export default ForgotPassword;
