import React, {useEffect} from 'react';
import {motion} from "framer-motion";
import {calculatePrice, convertCartItemToFood} from "../../services/food";
import T from "../../components/T";

const OutOfStock = ({in_stock}) => {

    return (
        !in_stock?
            <motion.div
                initial={{opacity: 0, scale: 5}}
                animate={{opacity: 1, scale: 1, transition: {delay: .1}}}
                key={`added-food-name`}
                className="absolute" style={{top: "20%"}}
            >
                <div className="text-white font-bold rounded-2xl uppercase shadow-2xl flex flex-row items-center justify-center w-full text-center bg-black p-2 px-8 sm:text-2xl" style={{letterSpacing: "0vw"}}>
                    <div style={{marginTop: "0vw"}}><T>Out of Stock</T></div>
                </div>
            </motion.div>
        :null
    );
};

export default OutOfStock;
