import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Card, Tag, Button, Divider} from 'antd';
import moment from 'moment';

import BranchInventory from '../../../../../http/BranchInventory';

const EndOfShiftNotice = ({notice, onAcknowledged}) => {
    // Current user from Redux store (so we don't hardcode IDs)
    const user = useSelector((state) => state.auth.user);
    const [acknowledging, setAcknowledging] = useState(false);

    // Basic logic to determine if the current user can acknowledge
    const canAcknowledge =
        !notice.acknowledged_at &&
        user &&
        notice.user_id === user.id;

    const handleAcknowledge = () => {
        setAcknowledging(true);

        // Adjust the API call name/method as needed
        BranchInventory.AcknowledgeEndOfShiftReportNotice(
            notice.id,
            (response) => {
                const updatedNotice = response.data.data.notice;
                // Inform parent if needed
                if (typeof onAcknowledged === 'function') {
                    onAcknowledged(notice.id, updatedNotice);
                }
                setAcknowledging(false);
            },
            (err) => {
                setAcknowledging(false);
            }
        );
    };

    // Safely handle the related report object (may be null or incomplete)
    const {report} = notice;
    if (!report) {
        // If there's no report at all, just display the notice
        return (
            <Card style={{marginBottom: 16}}>
                <h3>Notice #{notice.id}</h3>
                <p>
                    <strong>Type:</strong> {notice.type}
                </p>
                <p>
                    <strong>Description:</strong> {notice.description || 'N/A'}
                </p>
                <p>
                    <strong>Acknowledged:</strong>{' '}
                    {notice.acknowledged_at ? (
                        <Tag color="green">
                            Yes, on {moment(notice.acknowledged_at).format('YYYY-MM-DD HH:mm')}
                        </Tag>
                    ) : (
                        <Tag color="red">No</Tag>
                    )}
                </p>

                {canAcknowledge && (
                    <Button type="primary" loading={acknowledging} onClick={handleAcknowledge}>
                        Acknowledge
                    </Button>
                )}
            </Card>
        );
    }

    return (
        <Card style={{marginBottom: 16}}>
            {/* Notice Info */}
            <h3 style={{marginBottom: 8}}>Notice #{notice.id}</h3>

            <p>
                <strong>Date:</strong> {report.date}
            </p>

            <p>
                <strong>Type:</strong> {notice.type}
            </p>
            <p>
                <strong>Description:</strong> {notice.description || 'N/A'}
            </p>

            {/* Report Info */}
            <h3 style={{marginBottom: 8}}>Related Report #{report.id}</h3>
            <p>
                <strong>Issuer:</strong> {report.user?.name || 'N/A'}
            </p>

            <p>
                <strong>Shift:</strong> {report.shift}
            </p>

            <p>
                <strong>Acknowledged:</strong>{' '}
                {notice.acknowledged_at ? (
                    <Tag color="green">
                        Yes, on {moment(notice.acknowledged_at).format('YYYY-MM-DD HH:mm')}
                    </Tag>
                ) : (
                    <Tag color="red">No</Tag>
                )}
            </p>
            {/* Show the button at the end if the notice hasn't been acknowledged */}
            {canAcknowledge && !notice.acknowledged_at && (
                <Button type="primary" loading={acknowledging} onClick={handleAcknowledge}>
                    Acknowledge
                </Button>
            )}
        </Card>
    );
};

export default EndOfShiftNotice;
