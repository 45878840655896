import React from 'react';
import { notification } from "antd";
import { useSelector } from "react-redux";

const Contact = () => {
    const lang = useSelector(state => state.settings.lang);

    const submitMessage = (e) => {
        e.preventDefault();
        notification['success']({
            message: lang === 'fr' ? 'Succès !' : 'Success!',
            description:
                lang === 'fr'
                    ? 'Nous avons reçu votre message. Nous vous contacterons dès que possible.'
                    : 'We have received your message. We will contact you asap.',
        });
    };

    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white uppercase">
                    {lang === 'fr' ? 'Contactez-nous' : 'Contact Us'}
                </h2>
                <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
                    {lang === 'fr'
                        ? 'Vous avez un problème technique ? Vous souhaitez donner votre avis sur une fonctionnalité en version bêta ? Besoin de renseignements sur notre plan d\'affaires ? Faites-le nous savoir.'
                        : 'Got a technical issue? Want to send feedback about a beta feature? Need details about our Business plan? Let us know.'}
                </p>
                <form action="#" className="space-y-8" onSubmit={submitMessage}>
                    <div>
                        <label
                            htmlFor="email"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            {lang === 'fr' ? 'Votre courriel' : 'Your email'}
                        </label>
                        <input
                            type="email"
                            id="email"
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                            placeholder={lang === 'fr' ? 'nom@mail.com' : 'name@mail.com'}
                            required
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="subject"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            {lang === 'fr' ? 'Objet' : 'Subject'}
                        </label>
                        <input
                            type="text"
                            id="subject"
                            className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                            placeholder={lang === 'fr' ? 'Dites-nous comment nous pouvons vous aider' : 'Let us know how we can help you'}
                            required
                        />
                    </div>
                    <div className="sm:col-span-2">
                        <label
                            htmlFor="message"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                        >
                            {lang === 'fr' ? 'Votre message' : 'Your message'}
                        </label>
                        <textarea
                            id="message"
                            rows={6}
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder={lang === 'fr' ? 'Laissez un commentaire...' : 'Leave a comment...'}
                            defaultValue=""
                        />
                    </div>
                    <button
                        type="submit"
                        className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit bg-black"
                    >
                        {lang === 'fr' ? 'Envoyer le message' : 'Send message'}
                    </button>
                </form>
            </div>
        </section>
    );
};

export default Contact;
