import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, notification, Tag, Button, Divider } from 'antd';
import {
    RiUserLine,
    RiFileTextLine,
    RiAlertLine,
    RiFlagLine
} from '@remixicon/react';
import moment from 'moment';

import BranchInventory from '../../../../../http/BranchInventory';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import usePermission from '../../../../../layouts/usePermission';

import { useSelector } from "react-redux"; // or wherever you get the current user

const EndOfShiftReportShow = ({ _report, acknowledgable = false, user_id = null }) => {
    const { branchId, reportId } = useParams();
    const [loading, setLoading] = useState(true);
    const [acknowledging, setAcknowledging] = useState(false);
    const [report, setReport] = useState(null);
    const currentUser = useSelector(state => state.auth.user);

    const isManager = usePermission(null, [
        'inventory_assistant_general_manager',
        'inventory_general_manager',
    ]);

    // ------------------------------------------------------------------------
    // Load the report if not provided as a prop
    // ------------------------------------------------------------------------
    useEffect(() => {
        if (_report) {
            console.log("_report is:", _report);
            setReport(_report);
            setLoading(false);
            return;
        }

        if (reportId) {
            BranchInventory.ShowEndOfShiftReport(
                reportId,
                (response) => {
                    setReport(response.data.data.report);
                    setLoading(false);
                },
                (err) => {
                    notification.error({
                        message: 'Error!',
                        description: 'Failed to load end of shift report.',
                    });
                    setLoading(false);
                }
            );
        }
    }, [_report, reportId]);

    // ------------------------------------------------------------------------
    // Acknowledge a single notice in the pivot table
    // ------------------------------------------------------------------------
    const handleAcknowledgeNotice = (noticeId) => {
        setAcknowledging(true);

        BranchInventory.AcknowledgeEndOfShiftReportNotice(
            noticeId,
            (response) => {
                notification.success({
                    message: 'Acknowledged',
                    description: 'You have successfully acknowledged this notice.',
                });

                const updatedNotice = response.data.data.notice;
                setReport((prev) => {
                    if (!prev) return prev;
                    const newNotices = prev.notices.map((n) =>
                        n.id === updatedNotice.id ? updatedNotice : n
                    );
                    return { ...prev, notices: newNotices };
                });

                setAcknowledging(false);
            },
            (err) => {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to acknowledge the notice.',
                });
                setAcknowledging(false);
            }
        );
    };

    // ------------------------------------------------------------------------
    // Render a colored tag for the report's status
    // ------------------------------------------------------------------------
    const renderStatusTag = (status) => {
        if (status === 'pending') return <Tag color="orange">Pending</Tag>;
        if (status === 'confirmed') return <Tag color="green">Confirmed</Tag>;
        if (status === 'rejected') return <Tag color="red">Rejected</Tag>;
        return <Tag color="default">{status}</Tag>;
    };

    // ------------------------------------------------------------------------
    // Filter notices based on the provided user_id if valid
    // ------------------------------------------------------------------------
    const filteredNotices =
        report && report.notices && (user_id != null && user_id > 0)
            ? report.notices.filter(
                (notice) =>
                    notice.user && notice.user.id === user_id
            )
            : report && report.notices
                ? report.notices
                : [];

    // ------------------------------------------------------------------------
    // Render
    // ------------------------------------------------------------------------
    return (
        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
            <DefaultContainer>
                {report && (
                    <Card
                        title={
                            <div className="flex items-center space-x-2">
                                <RiFileTextLine className="text-xl" />
                                <span className="text-lg font-semibold">
                                    End of Shift Report - {report.date} ({report.shift})
                                </span>
                            </div>
                        }
                        className="shadow-md rounded-lg border-t-4 border-blue-500"
                    >
                        {/* Issuer */}
                        <div className="flex items-center my-2">
                            <RiUserLine className="text-xl mr-2" />
                            <div className="font-medium text-gray-600">
                                <strong>Issuer:</strong> {report.user?.name || 'N/A'}
                            </div>
                        </div>
                        <Divider dashed />

                        {/* Status + Edit (if manager and not in acknowledgable mode) */}
                        <div className="my-2">
                            <strong>Status: </strong>
                            {renderStatusTag(report.status)}
                            {!acknowledgable && isManager && (
                                <Link
                                    to={`/dashboard/operations/daily-logs/end-of-shift/${report.branch_id}/report/${report.id}/edit`}
                                >
                                    <Button type="link">Edit</Button>
                                </Link>
                            )}
                        </div>
                        <Divider dashed />

                        {/* Summary */}
                        <div className="my-2 flex items-center">
                            <RiAlertLine className="text-xl mr-2" />
                            <div className="font-medium text-gray-600">
                                <strong>Summary of Operations:</strong> {report.summary}
                            </div>
                        </div>
                        <Divider dashed />

                        {/* Challenges */}
                        <div className="flex items-center my-2">
                            <RiFlagLine className="text-xl mr-2" />
                            <div className="font-medium text-gray-600">
                                <strong>Challenges Encountered:</strong> {report.challenges}
                            </div>
                        </div>
                        <Divider dashed />

                        {/* Materials Out of Stock */}
                        <div className="my-2">
                            <div className="font-medium text-gray-600">
                                <strong>Materials Out of Stock:</strong> {report.out_of_stock_materials}
                            </div>
                        </div>
                        <Divider dashed />

                        {/* Unresolved Issues */}
                        <div className="flex items-center my-2">
                            <div className="font-medium text-gray-600">
                                <strong>Unresolved Issues:</strong> {report.unresolved_issues}
                            </div>
                        </div>
                        <Divider dashed />

                        {/* Incidents */}
                        <div className="my-2">
                            <div className="font-medium text-gray-600">
                                <strong>Incidents:</strong>{' '}
                                {report.incidents ? <Tag color="red">Yes</Tag> : <Tag color="green">No</Tag>}
                            </div>
                            {report.incidents && (
                                <div className="font-medium text-gray-600 mt-4">
                                    <strong>Incident Description:</strong> {report.incident_description}
                                </div>
                            )}
                        </div>
                        <Divider dashed />

                        {/* Notices (filtered) */}
                        <div className="my-2">
                            <div className="font-medium text-gray-600">
                                <strong>Notices:</strong>{' '}
                                {filteredNotices && filteredNotices.length > 0 ? (
                                    <Tag color="orange">Yes</Tag>
                                ) : (
                                    <Tag color="green">No</Tag>
                                )}
                            </div>

                            {filteredNotices && filteredNotices.length > 0 && (
                                <div className="mt-4">
                                    {filteredNotices.map((notice) => (
                                        <div
                                            key={notice.id}
                                            style={{
                                                marginBottom: '16px',
                                                border: '1px solid #e8e8e8',
                                                padding: '16px',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            <div className="font-medium text-gray-600">
                                                <strong>Type:</strong> {notice.type}
                                            </div>
                                            <div className="font-medium text-gray-600 mt-2">
                                                <strong>Recipient:</strong>{' '}
                                                {notice.user ? notice.user.name : 'N/A'}
                                            </div>
                                            <div className="font-medium text-gray-600 mt-2">
                                                <strong>Description:</strong> {notice.description || 'N/A'}
                                            </div>
                                            <div className="font-medium text-gray-600 mt-2">
                                                <strong>Acknowledged:</strong>{' '}
                                                {notice.acknowledged_at ? (
                                                    <Tag color="green">
                                                        Yes, on {moment(notice.acknowledged_at).format('DD/MM/YYYY HH:mm')}
                                                    </Tag>
                                                ) : (
                                                    <Tag color="red">No</Tag>
                                                )}
                                            </div>

                                            {acknowledgable &&
                                                !notice.acknowledged_at &&
                                                notice.user &&
                                                currentUser &&
                                                notice.user.id === currentUser.id && (
                                                    <div className="mt-2">
                                                        <Button
                                                            type="primary"
                                                            loading={acknowledging}
                                                            onClick={() => handleAcknowledgeNotice(notice.id)}
                                                        >
                                                            Acknowledge
                                                        </Button>
                                                    </div>
                                                )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </Card>
                )}
            </DefaultContainer>
        </SkeletonWrapper>
    );
};

export default EndOfShiftReportShow;
