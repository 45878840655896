import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SkeletonWrapper from "../../components/skeletons/SkeletonWrapper";
import MenuSkeleton from "../../components/skeletons/MenuSkeleton";
import CartItem from "./CartItem";
import {calculatePrice, convertCartItemToFood} from "../../services/food";
import {Button, Modal} from "antd";
import {checkout, CheckoutReset, CheckoutStoreReset} from "../../redux/checkout/actionCreator";
import {useHistory} from "react-router";
import {roundToTwo} from "../../services/global";
import {motion} from "framer-motion";
import {SetRedirectSource} from "../../redux/authentication/actionCreator";
import {closeStoreCartBubble, closeUpdateCartBubble, EmptyCart, removeFromCart} from "../../redux/cart/actionCreator";
import "../../config";
import T from "../../components/T";
import Settings from "../../http/Settings";
import {UpdateMenu} from "../../redux/menu/actionCreator";
import {RiMapPinLine} from "@remixicon/react";

const translateErrorMessage = (error, lang) => {
    // If language is not French, return the original error
    if (lang !== 'fr') return error;

    // 1) Sorry, Patty Slaps is now closed...
    if (error.includes('Sorry, Patty Slaps is now closed. We are open from 11am to 2am. See you soon.')) {
        return 'Désolé, Patty Slaps est maintenant fermé. Nous sommes ouverts de 11h à 2h. À bientôt.';
    }

    // 2) The store is currently closed.
    if (error.includes('The store is currently closed.')) {
        return 'Le magasin est actuellement fermé.';
    }

    // 3) Sorry, the operating hours for this branch are not available...
    if (error.includes('Sorry, the operating hours for this branch are not available at the moment.')) {
        return 'Désolé, les horaires d\'ouverture de cette succursale ne sont pas disponibles pour le moment.';
    }

    // Fallback if it doesn't match any known message
    return error;
};

export default function Cart() {
    const dispatch = useDispatch();
    const history = useHistory();

    // Redux states
    const menu = useSelector(state => state.menu.index_menu);
    const loading = useSelector(state => state.menu.index_loading);
    const error = useSelector(state => state.menu.index_error);
    const cart_items = useSelector(state => state.cart.cart_items);
    const checkout_loading = useSelector(state => state.checkout.store_loading);
    const checkout_done = useSelector(state => state.checkout.store_done);
    const checkout_error = useSelector(state => state.checkout.store_error);
    const branches = useSelector(state => state.menu.index_branches);
    const lang = useSelector(state => state.settings.lang);

    const [disabled, setDisabled] = useState(false);
    const [branch, setBranch] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [tax, setTax] = useState(0);

    // Example tax rates from global config
    const [taxRates] = useState(global.config.taxRates);

    let branch_guid = localStorage.getItem('branch_guid');

    // Handle branches
    useEffect(() => {
        if (!branches) return;
        if (!branch_guid) {
            history.push("/branch");
            return;
        }
        const foundBranch = branches.find(b => b.guid === branch_guid);
        if (!foundBranch) {
            history.push("/branch");
            return;
        }
        setBranch(foundBranch);
    }, [branches, branch_guid, history]);

    // Reset checkout on mount
    useEffect(() => {
        dispatch(CheckoutReset());
        SetRedirectSource(null);
    }, [dispatch]);

    // Calculate cart totals
    useEffect(() => {
        if (menu && cart_items) {
            let tp = 0;
            for (const key in cart_items) {
                const storageCartItem = cart_items[key];
                const selectedItems = storageCartItem[1];
                const quantity = storageCartItem[2];
                const food = convertCartItemToFood(menu, storageCartItem);
                if (food == null) {
                    dispatch(EmptyCart());
                    return;
                }
                const price = calculatePrice(food, selectedItems);
                tp += (price * quantity);
            }
            setTotalPrice(tp);

            let totalTax = 0;
            taxRates.forEach(rate => {
                totalTax += Number((tp * rate).toFixed(2));
            });
            setTax(totalTax);
        }
    }, [menu, cart_items, dispatch, taxRates]);

    const handleCheckout = () => {
        setDisabled(true);
        dispatch(checkout());
    };

    // Handle checkout error + translation
    useEffect(() => {
        if (checkout_error) {
            setDisabled(false);
            const translatedError = translateErrorMessage(checkout_error, lang);

            Modal.warning({
                title: lang === 'fr' ? 'Erreur de commande' : 'Checkout Error',
                content: translatedError,
                onOk() {
                    dispatch(CheckoutStoreReset());
                },
            });
        }
    }, [checkout_error, checkout_loading, lang, dispatch]);

    // Navigate to checkout page on success
    useEffect(() => {
        if (checkout_done === true) {
            history.push("/checkout");
        }
    }, [checkout_done, checkout_loading, history]);

    const totalItems = cart_items.reduce((sum, current) => sum + current[2], 0);

    return (
        <SkeletonWrapper loading={loading} skeleton={MenuSkeleton}>
            {cart_items && cart_items.length > 0 ? (
                <motion.div
                    initial={{ opacity: 0, y: -200 }}
                    animate={{ opacity: 1, y: 0 }}
                    style={{ paddingBottom: "5vw" }}
                >
                    <div className="row mt-5 lg:mt-0">
                        <div className="md:container flex flex-col mx-auto px-3 py-0 lg:p-10">
                            <h1 className="text-4xl font-bold text-black uppercase">
                                <T>MY BAG</T>
                            </h1>
                            <div className="row flex mt-2 flex-col lg:flex-row relative">
                                <div className="w-full lg:w-2/3 lg:pr-3 flex flex-col">
                                    {cart_items.map(item => (
                                        <CartItem
                                            storageCartItem={item}
                                            key={item[3]}
                                            disabled={disabled || checkout_loading}
                                        />
                                    ))}
                                </div>
                                <div className="w-full lg:w-1/3 sticky">
                                    <div className="rounded-2xl border shadow-xl bg-white p-4 lg:p-8 flex flex-col">
                                        <h1 className="text-2xl font-bold mb-3 mx-2 uppercase">
                                            {totalItems} <T>{totalItems === 1 ? 'ITEM' : 'ITEMS'}</T>
                                        </h1>
                                        <hr />
                                        {branch && false && (
                                            <div className="flex flex-row items-center bg-gray-200 px-2 py-2 rounded-lg mt-2">
                                                <RiMapPinLine size={14} className="mr-1" />
                                                {branch.display_name}
                                            </div>
                                        )}
                                        <div className="m-3 mt-2 flex flex-col">
                                            <div className="row flex my-2">
                                                <span className="flex-1 capitalize"><T>Subtotal</T></span>
                                                <span>${roundToTwo(totalPrice)}</span>
                                            </div>
                                            <div className="row flex mb-2">
                                                <span className="flex-1 capitalize"><T>Tax</T></span>
                                                <span>${roundToTwo(tax)}</span>
                                            </div>
                                            <div className="row flex mb-2">
                                                <span className="flex-1 capitalize"><T>Estimated Total</T></span>
                                                <span>${roundToTwo(totalPrice + tax)}</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <Button
                                            type="primary"
                                            shape="round"
                                            size="large"
                                            className="hover:bg-red-700 text-white text-xl font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline mt-4 flex flex-row flex-1 items-center block"
                                            style={{ display: "flex", backgroundColor: "black", border: "none" }}
                                            loading={disabled || checkout_loading}
                                            onClick={handleCheckout}
                                        >
                                            <div className="text-white hover:text-white flex-1 text-left capitalize">
                                                <T>Checkout</T>
                                            </div>
                                            <div className="text-white hover:text-white">
                                                ${roundToTwo(totalPrice + tax)}
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            ) : (
                <motion.div
                    initial={{ opacity: 0, y: -200 }}
                    animate={{ opacity: 1, y: 0 }}
                    style={{ paddingBottom: "5vw" }}
                >
                    <div className="card flex items-center flex-col m-10 p-5 bg-white rounded-2xl">
                        <div className="card-body cart">
                            <div className="col-sm-12 empty-cart-cls text-center text-lg">
                                <img src="/images/cart-empty.png" className="mb-8 w-64" alt="Empty Cart" />
                                <div className="text-2xl font-bold capitalize">
                                    <strong><T>Your Cart is Empty</T></strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}
        </SkeletonWrapper>
    );
}
