import React from 'react';
import DefaultContainer from '../../../../../components/DefaultContainer';
import EndOfShiftReportShow from "../../Inventory/EndOfShiftReports/EndOfShiftReportShow";


const EmployeeNotices = ({employee}) => {
    return (
        employee.notices ?
            employee.notices.map(notice => {
                return (<div className={"mb-1"}><EndOfShiftReportShow _report={notice.report} user_id={employee.id} /></div>)
            })
        :
            <div>
                There are no notices for this user.
            </div>
    );
};

export default EmployeeNotices;
