import React, {useEffect, useState} from 'react';
import Item from "./Item";
import {useDispatch, useSelector} from "react-redux";
import {motion} from "framer-motion";
import T from "../../components/T";
import {selectItem} from "../../redux/food/actionCreator";

const Option = ({option, index}) => {
    const dispatch = useDispatch();
    const selectedItems = useSelector(state => state.food.selectedItems);
    const food = useSelector(state => state.food.food);
    const options = useSelector(state => state.menu.index_options);
    const [isInStock, setIsInStock] = useState(true);
    const [enabled, setEnabled] = useState(true);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selected, setSelected] = useState(false);

    useEffect(() => {

        const foundOption = options.find(opt => opt.id === option.id);
        const _isInStock = foundOption && foundOption.in_stock === 1;
        const _enabled = foundOption && foundOption.status === 1;

        setIsInStock(_isInStock);
        setEnabled(_enabled);

        if (!_isInStock) {
            console.log("Dispatched the selectItem to select the first item of out of stock option", food, option.name, option.items[0]);
            if (!selectedItems.includes(option.items[0].id))
                setTimeout(() => {
                    dispatch(selectItem(food, selectedItems, option.items[0].id));
                }, 100);
            setSelected(true);
            setTotalPrice(0);
        } else {
            let tp = 0;
            option.items.map((item) => {
                if (selectedItems.includes(item.id)) {
                    setSelected(true);
                    tp += item.price;
                }
            });
            setTotalPrice(tp);
        }

        console.log("Total price:", totalPrice);

    }, [option, options, selectedItems]);

    const x = (m) => {
        return m ? "100px" : "120px"
    }

    return (
        enabled &&
        <div className={"w-full"} key={`option${option.id}`} style={{padding: "2px", marginTop: "4px"}}>
            <div className="bg-white flex flex-col" style={{padding: ".5vw"}}>
                <div className="flex flex-row">
                    <div className={`flex flex-row items-center justify-center ${option.multiselect ? '' : ''}`} style={{height: x(option.multiselect), margin: ".0vw 2vw .0vw 2vw"}}>
                        {selected ?
                            option.items.map((item) => {
                                if (selectedItems.includes(item.id)) {
                                    return (
                                        <div style={{height: x(option.multiselect), width: x(option.multiselect)}}>
                                            <motion.div initial={{opacity: .5, scale: 1.5}}
                                                        animate={{opacity: 1, scale: 1}}
                                            >
                                                <img src={item.image} style={{height: x(option.multiselect)}}/>
                                            </motion.div>
                                        </div>
                                    )
                                } else return null;
                            })
                            :
                            <div className="text-gray-300 uppercase text-2xl flex flex-col items-center justify-center">
                                <div>NO</div>
                                <div>{option.name}</div>
                                <div>Selected</div>
                            </div>
                        }
                    </div>
                    <div className={`flex flex-row items-center`} style={{paddingRight: option.multiselect ? '2vw' : ''}}>
                        <div className="items-start justify-start flex flex-col pl-3 text-lg">
                            <div className="font-bold"><T cap_if_all_lowercase fr={option.name_fr}>{option.name}</T></div>
                            {totalPrice > 0 &&
                                <div>
                                    <span>+${totalPrice.toFixed(2)}</span>
                                </div>
                            }
                        </div>
                        {!isInStock ? <div className={"bg-brand rounded-full text-lg py-1 ml-5 text-white px-5 font-bold uppercase bg-black"}><T>Out of Stock</T></div> : <></>}
                    </div>
                </div>

                <div className="flex flex-row w-full text-center">
                    {option.items.map((item) => {
                        return (
                            <Item item={item}/>
                        )
                    })}
                </div>
            </div>
        </div>

    );
};

export default Option;
