import React, {useEffect, useState} from 'react';
import Styled from "styled-components";
import {Collapse, Divider, notification, Pagination, Progress} from 'antd';
import {roundToTwo} from "../../../services/global";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faMedal, faStar, faStop, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {useParams} from "react-router-dom";
import {ValidateCheckout, ValidateCheckoutReset} from "../../../redux/checkout/actionCreator";
import CheckoutItem from "../Profile/CheckoutItem";
import UserMenu from "../Profile/UserMenu";
import SkeletonWrapper from "../../../components/skeletons/SkeletonWrapper";
import MenuSkeleton from "../../../components/skeletons/MenuSkeleton";
import OrderSkeleton from "../../../components/skeletons/OrderSkeleton";
import Pullable from "react-pullable";
import {SpinnerCircular, SpinnerDotted} from "spinners-react";
import {SetRedirectSource} from "../../../redux/authentication/actionCreator";
import {motion} from "framer-motion";
import Echo from "laravel-echo";
import T from "../../../components/T";
import {Translate} from "../../../helpers/Translate";

const {Panel} = Collapse;

const ValidateOrder = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const order = useSelector(state => state.checkout.validate_checkout);
    const message = useSelector(state => state.checkout.validate_message);
    const loading = useSelector(state => state.checkout.validate_loading);
    const error = useSelector(state => state.checkout.validate_error);
    const [seconds_passed, set_seconds_passed] = useState(0);
    const device_uuid = localStorage.getItem('device_uuid');
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const [startTime, setStartTime] = useState(null);

    const {uid} = useParams();
    let location = useLocation();

    if(!isLoggedIn) {
        const orders = JSON.parse(localStorage.getItem('order_uids')) || [];
        const orderId = uid;

        if (!orders.includes(orderId)) {
            orders.push(orderId);
            localStorage.setItem('order_uids', JSON.stringify(orders));
        }
    }

    //console.log("uid", uid);
    //console.log("location", location);

    function  handleTimerFinish() {
        alert("times up!");
    }

    const handleSwiftIncoming = (data) => {

        const params = window.location.pathname.split("/");
        let uid = "";
        if(params.length == 3) {
            if(params[1] == "order") {
                uid = params[2];
            }
        }

        if (data && typeof data === "string" &&  data.includes(":")) {
            const parts = data.split(":");

            if (parts[0] == "refresh" || parts[0] == "hard-refresh")
            {
                if(uid == parts[1]) {
                    dispatch(ValidateCheckout(uid));
                }
            }

            if (parts[0] == "uuid") {
                //notification['success']({message: 'UUID Received.',description: parts[1],});
                const current_device_uuid = localStorage.getItem("device_uuid");
                if (current_device_uuid != parts[1]) {
                    localStorage.setItem('device_uuid', parts[1]);
                    dispatch(ValidateCheckout(uid));
                }
            }

        }
    }

    let timer = null;
    let start_timestamp = Date.now();

    const calculateElapsedTime = () => {
        const elapsedSeconds = Math.floor((Date.now() - start_timestamp) / 1000);
        return elapsedSeconds;
    };

    const startTimer = () => {
        timer = setInterval(() => {
            set_seconds_passed(calculateElapsedTime());
        }, 1000);
    };

    useEffect(() => {

        console.log("useEffect reached here!");

        set_seconds_passed(0);
        start_timestamp = Date.now();

        if (timer) {
            console.log("Timer cleared!");
            clearInterval(timer);
        }

        if (order) {
            if (order.fulfillment_status === "reserved" || order.fulfillment_status === "proposed") {
                startTimer();
            }
            console.log("Order details (prep_remaining, prep_total):", order.prep_remaining, order.prep_total);
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [order]);


    useEffect(() => {
        if(device_uuid) {
            window.addEventListener("message", message => {
                let data = message.data;
                handleSwiftIncoming(data);
            });

            return () => {
                if(timer) {
                    clearInterval(timer);
                    timer = null;
                    start_timestamp = Date.now();
                }
            }

        }
    }, []);

    useEffect(() => {
        const options = {
            broadcaster: 'pusher',
            key: 'b7e32114e18a5c7cdb81',
            cluster: 'mt1',
            forceTLS: true,
        };

        let echo = new Echo(options);

        if(uid) {
            console.log("we are going to open the channel: ", `checkout-${uid}`);
            echo.channel(`checkout-${uid}`)
                .listen('.checkout-fulfillment-updated', (e) => {
                    console.log(e);
                    notification['success']({message: e.title, description: e.body, placement: 'topRight'});
                    dispatch(ValidateCheckout(uid));
                });
        }

        return () => {
            if(echo && order) {
                echo.leave(`checkout-${order.uuid}`);
                console.log("Leaved the channel!!!");
            }
        };

    }, [uid]);

    useEffect(() => {
        //notification['success']({message: 'useEffect called:' + uid,placement: 'bottom'});
        dispatch(ValidateCheckoutReset());
        dispatch(ValidateCheckout(uid));
        dispatch(SetRedirectSource(`/order/${uid}`));
    }, [uid]);

    const handleRefresh = () => {
        dispatch(ValidateCheckout(uid));
    }

    const formatRemainingTime = () => {
        const remainingTime = Math.abs(order.prep_remaining - seconds_passed);
        const minutes = Math.floor(remainingTime / 60);
        const seconds = remainingTime % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <Pullable
            onRefresh={handleRefresh}
            spinnerSize={34}
            refreshDuration={500}
        >
            <motion.div className=""
                        initial={{opacity: 0, scale: 1, y: -100}}
                        animate={{opacity: 1, scale: 1, y: 0}}
            >
            <div className="flex flex-col items-center justify-center mt-4">
                <div className="row w-full">
                    <div className="md:container flex flex-col mx-auto pb-10 pt-2 px-2">
                        <h1 className="text-2xl font-bold text-black pl-3 uppercase"><T cap>Order Details</T></h1>
                        <div className="w-full flex flex-col lg:flex-row">
                            <div className="rounded-4xl flex-1 border shadow-xl bg-white p-2 flex flex-col items-center justify-center">
                                <SkeletonWrapper loading={order == null && loading} skeleton={location.pathname.includes("validate") ? MenuSkeleton : MenuSkeleton}>
                                    {error ?
                                        <div className="text-red-900 text-base py-10 px-5">
                                            <FontAwesomeIcon icon={faTriangleExclamation} className="mr-2 text-lg" aria-hidden="true"/>
                                            {error}</div>
                                        : null}
                                    {order ?
                                        <div
                                            className={`shadow-lg flex flex-col border border-gray-300 overflow-hidden w-full text-lg w-full`}
                                            style={{margin: "0vw 0vw 0vw 0vw", padding: ".5vw 1vw", borderRadius: ".8vw"}}
                                        >
                                            {order.payment_status != "success" ?
                                                <div className="flex flex-row flex-1 justify-center py-4 text-red-800 bg-red-100 font-bold items-center mb-2">
                                                    <FontAwesomeIcon icon={faTriangleExclamation} className="mr-2 text-2xl" aria-hidden="true"/>
                                                    This order is not paid yet.
                                                </div>
                                                :
                                                null
                                            }
                                            <div>

                                            </div>
                                            <div className="flex flex-col" style={{padding: "1vw 1vw 0vw 1vw"}}>
                                                <div className="font-bold flex flex-row items-center w-full justify-between lg:text-2xl pb-2 lg:mb-0 text-2xl">
                                                    <div className="mr-3 text-lg font-normal"><T>Name</T>: <b>{order.first_name}</b></div>
                                                    <div className="flex flex-row items-center">
                                                        {order.points > 0? <div className={"text-base flex flex-row items-center ml-2 px-2 py-0 bg-blue-100 rounded-lg"}><FontAwesomeIcon icon={faMedal} className="block text-sm mr-1" aria-hidden="true"/> {order.points}</div> : null}
                                                    </div>
                                                </div>
                                                <div className="flex flex-row flex-1 w-full">
                                                    <div className="flex flex-row items-center">
                                                        <div className="bg-gray-200 px-3 py-2 rounded-xl text-sm">
                                                            {order.payed_at_hr}
                                                        </div>

                                                        <div className="uppercase ml-2 px-2 bg-green-100 rounded-lg text-xs flex items-center justify-center font-bold" style={{padding: "10px 10px"}}>
                                                            <T cap>{order.type}</T>
                                                        </div>
                                                    </div>
                                                    {order.payment_status != "success" ?
                                                        <div className="flex flex-row flex-1 justify-end text-red-800 font-bold items-center">
                                                            <FontAwesomeIcon icon={faStop} className="mr-2 text-2xl" aria-hidden="true"/>
                                                            <T cap>Not Paid</T>
                                                        </div>
                                                        :
                                                        <div className="flex flex-row flex-1 justify-end text-green-800 font-bold items-center mx-5">
                                                            <FontAwesomeIcon icon={faCheck} className="mr-2 text-2xl" aria-hidden="true"/>
                                                            <T cap>Paid</T>
                                                        </div>
                                                    }
                                                </div>
                                            </div>


                                            {order.payment_status == "success" ?
                                                <div className={`
                                                    text-sm flex flex-row items-center justify-center uppercase mt-4 py-2 text-white 
                                                    ${order.fulfillment_status=='prepared' ? 'bg-green-700' : null} 
                                                    ${order.fulfillment_status=='completed' ? 'bg-black' : null}
                                                    ${order.fulfillment_status=='created' || order.fulfillment_status=='proposed' ? 'bg-blue-100' : null}
                                                    ${order.fulfillment_status=='reserved' ? 'bg-blue-100' : null}                                                    
                                                    ${order.fulfillment_status=='canceled' || order.fulfillment_status=='failed' ? 'bg-red-700' : null}
                                                `}>
                                                    {loading ?
                                                        <SpinnerDotted size={23} color="rgba(255, 255, 255, 1)" secondaryColor="rgba(255, 255, 255, 0.44)" />
                                                    :
                                                        <div>

                                                            {order.fulfillment_status == "reserved" || order.fulfillment_status == "created" || order.fulfillment_status == "proposed"?
                                                                <div className="flex flex-col items-center justify-center text-black">
                                                                    <div className="flex flex-row pr-1 text-black font-bold">
                                                                        <SpinnerCircular size={20} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.14)" className="mr-3"/>
                                                                        <T cap>Your order is being prepared</T>
                                                                    </div>

                                                                    {order.prep_remaining - seconds_passed > 0?
                                                                    <div className="text-xs text-gray-300 py-4">
                                                                        <Progress
                                                                            type="circle"
                                                                            strokeColor={{
                                                                                '0%': '#108ee9',
                                                                                '100%': '#87d068',
                                                                            }}
                                                                            format={formatRemainingTime}
                                                                            percent={100 - parseInt((order.prep_remaining - seconds_passed) / order.prep_total * 100)}
                                                                        />
                                                                    </div>
                                                                    :
                                                                        <div className="p-2">
                                                                            <div className="text-center p-2 bg-red-600 text-white w-full">
                                                                                <T>We're sorry for any wait you're experiencing. Our team is working hard to whip up your tasty meal.</T>
                                                                            </div>
                                                                            <div className={"text-center mt-2 flex flex-row items-center justify-center"}>
                                                                                <div className={" font-bold text-base min-w-10 mr-1"}>{formatRemainingTime()}</div>
                                                                                <div style={{padding: "2px 0px 0px 0px"}}>passed since our estimation!</div>
                                                                            </div>
                                                                        </div>
                                                                    }



                                                                </div>
                                                            : null}

                                                            {order.fulfillment_status == "prepared" ?
                                                                <div className="flex flex-row pr-1 flex items-center">
                                                                    <FontAwesomeIcon icon={faCheck} className="mr-2 text-xl mb-1" aria-hidden="true"/>
                                                                    <T cap>Your order is ready</T>
                                                                </div>
                                                            : null}

                                                            {order.fulfillment_status == "completed" ?
                                                                <div className="flex flex-row pr-1 flex items-center">
                                                                    <FontAwesomeIcon icon={faCheck} className="mr-2 text-xl mb-1" aria-hidden="true"/>
                                                                    <T cap>Your Order has been picked Up</T>
                                                                </div>
                                                            : null}

                                                            {order.fulfillment_status == "canceled" || order.fulfillment_status == "failed"?
                                                                <div className="flex flex-row pr-1 flex items-center">
                                                                    <FontAwesomeIcon icon={faCheck} className="mr-2 text-xl mb-1" aria-hidden="true"/>
                                                                    <T cap>Your Order has been canceled! Please ask the manager about this incident.</T>
                                                                </div>
                                                            : null}

                                                        </div>
                                                    }
                                                </div>
                                                : null}

                                            <div className="flex flex-col p-2 pt-0">
                                                <div className="p-4 px-2">
                                                    <Panel header={<>{order.items.reduce(function (sum, current) {
                                                        return sum + current.quantity;
                                                    }, 0)} <T upper>ITEMS</T></>} key="1" className="">
                                                    </Panel>
                                                </div>

                                                <div>
                                                    {order.items.map((item, index) => {
                                                        return <OptionsWrapper key={index}><CheckoutItem item={item}/></OptionsWrapper>
                                                    })}
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                </SkeletonWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </motion.div>
        </Pullable>

    );
};

export default ValidateOrder;

const OptionsWrapper = Styled.div`
    span {        
        margin-right:8px;
        color:black;
        font-weight:500;
    }    
    i {
        padding-left:0px;
        color:gray;
        font-size:13px;
    }
`;
