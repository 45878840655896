import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import {
    Button,
    notification,
    Pagination,
    Tag,
    Space,
    Table,
    Select
} from 'antd';
import moment from 'moment';
import Styled from 'styled-components';

import BranchInventory from '../../../../../http/BranchInventory';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import usePermission from '../../../../../layouts/usePermission';

const ContainerWrapper = Styled.div`
  .ant-card-body div {
    white-space: nowrap;
    width: auto !important;
  }
`;

const EndOfShiftReportsIndex = () => {
    const { branchId } = useParams();
    const location = useLocation();
    const history = useHistory();

    const isManager = usePermission(null, [
        'inventory_assistant_general_manager',
        'inventory_general_manager',
    ]);

    // --------------------------------------------------
    // Parse query params from the URL safely
    // --------------------------------------------------
    const getQueryParams = useCallback(() => {
        const params = new URLSearchParams(location.search);

        const pageParam = parseInt(params.get('page'), 10);
        const pageSizeParam = parseInt(params.get('pageSize'), 10);

        return {
            page: Number.isNaN(pageParam) ? 1 : pageParam,
            pageSize: Number.isNaN(pageSizeParam) ? 10 : pageSizeParam,
            sortField: params.get('sortField') || 'created_at',
            sortOrder: params.get('sortOrder') || 'desc',
            noticeUserId: params.get('notice_user_id') || '',
        };
    }, [location.search]);

    const initialParams = getQueryParams();

    // --------------------------------------------------
    // States
    // --------------------------------------------------
    const [pagination, setPagination] = useState({
        current: initialParams.page,
        pageSize: initialParams.pageSize,
        total: 0,
    });

    const [sorter, setSorter] = useState({
        field: initialParams.sortField,
        order: initialParams.sortOrder,
    });

    const [noticeUserFilter, setNoticeUserFilter] = useState(initialParams.noticeUserId);

    // Data & loading
    const [reports, setReports] = useState([]);
    const [noticeUsers, setNoticeUsers] = useState([]);
    const [loading, setLoading] = useState(true);      // for initial skeleton
    const [tableLoading, setTableLoading] = useState(false);
    const [didInitialLoad, setDidInitialLoad] = useState(false);

    // --------------------------------------------------
    // Sync changes from URL to local states
    // --------------------------------------------------
    useEffect(() => {
        const {
            page,
            pageSize,
            sortField,
            sortOrder,
            noticeUserId
        } = getQueryParams();

        const isDifferent =
            page !== pagination.current ||
            pageSize !== pagination.pageSize ||
            sortField !== sorter.field ||
            sortOrder !== sorter.order ||
            noticeUserId !== noticeUserFilter;

        if (isDifferent) {
            setPagination((prev) => ({
                ...prev,
                current: page,
                pageSize,
            }));
            setSorter({ field: sortField, order: sortOrder });
            setNoticeUserFilter(noticeUserId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    // --------------------------------------------------
    // Fetch data whenever relevant states change
    // --------------------------------------------------
    useEffect(() => {
        if (branchId) {
            loadReports();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branchId, pagination.current, pagination.pageSize, sorter.field, sorter.order, noticeUserFilter]);

    const loadReports = () => {
        if (!didInitialLoad) {
            setLoading(true);
        } else {
            setTableLoading(true);
        }

        BranchInventory.IndexEndOfShiftReports(
            branchId,
            {
                params: {
                    per_page: pagination.pageSize,
                    page: pagination.current,
                    sort_by: sorter.field,
                    sort_direction: sorter.order === 'ascend' ? 'asc' : 'desc',
                    notice_user_id: noticeUserFilter || undefined,
                },
            },
            (response) => {
                if (response?.data?.data) {
                    const { reports: paginatedReports, notice_users } = response.data.data;

                    // Convert each report for the table
                    const processedReports = paginatedReports.data.map((record) => {
                        // Build a small summary of notices
                        let noticesSummary = 'None';
                        if (record.notices && record.notices.length > 0) {
                            // For example: "Warning for John Doe, Notice for Jane Smith"
                            noticesSummary = record.notices
                                .map((n) => `${n.type} for ${n.user ? n.user.name : 'Unknown'}`)
                                .join(', ');
                        }

                        return {
                            ...record,
                            userName: record.user?.name || '-',
                            createdAtFormatted: moment(record.created_at).format('DD/MM/YYYY HH:mm'),
                            noticesSummary,
                        };
                    });

                    setReports(processedReports);
                    setPagination((prev) => ({
                        ...prev,
                        total: paginatedReports.total,
                    }));

                    // Convert the "notice_users" array for the dropdown
                    const filteredNoticeUsers = (notice_users || [])
                        .filter((item) => item.user !== null)
                        .map((item) => ({
                            id: item.user.id,
                            name: item.user.name,
                            count: item.count,
                        }));

                    setNoticeUsers(filteredNoticeUsers);
                } else {
                    notification.error({
                        message: 'Error!',
                        description: 'Unexpected response format while loading reports.',
                    });
                }

                setLoading(false);
                setTableLoading(false);
                setDidInitialLoad(true);
            },
            (err) => {
                notification.error({
                    message: 'Error!',
                    description:
                        err.response?.data?.message || 'Failed to load end of shift reports.',
                });
                setLoading(false);
                setTableLoading(false);
                setDidInitialLoad(true);
            }
        );
    };

    // --------------------------------------------------
    // Update URL query params
    // --------------------------------------------------
    const updateURL = (newParams) => {
        const params = new URLSearchParams(location.search);

        Object.keys(newParams).forEach((key) => {
            // Remove param if null, undefined, or empty string
            if (newParams[key] !== undefined && newParams[key] !== null && newParams[key] !== '') {
                params.set(key, newParams[key]);
            } else {
                params.delete(key);
            }
        });

        history.push({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    // --------------------------------------------------
    // Table & Pagination
    // --------------------------------------------------
    const handleTableChange = (paginationConfig, filters, sorterConfig) => {
        const newPagination = {
            current: paginationConfig.current,
            pageSize: paginationConfig.pageSize,
            total: pagination.total,
        };
        const newSorter = {
            field: sorterConfig.field || sorter.field,
            order: sorterConfig.order || sorter.order,
        };

        setPagination(newPagination);
        setSorter(newSorter);

        updateURL({
            page: newPagination.current,
            pageSize: newPagination.pageSize,
            sortField: newSorter.field,
            sortOrder: newSorter.order,
            notice_user_id: noticeUserFilter,
        });
    };

    const handlePageChange = (page, pageSize) => {
        setPagination((prev) => ({ ...prev, current: page, pageSize }));
        updateURL({
            page,
            pageSize,
            sortField: sorter.field,
            sortOrder: sorter.order,
            notice_user_id: noticeUserFilter,
        });
    };

    const handlePerPageChange = (current, size) => {
        setPagination((prev) => ({ ...prev, pageSize: size, current: 1 }));
        updateURL({
            page: 1,
            pageSize: size,
            sortField: sorter.field,
            sortOrder: sorter.order,
            notice_user_id: noticeUserFilter,
        });
    };

    // --------------------------------------------------
    // Quick calls to update a report's status
    // --------------------------------------------------
    const updateReportStatus = (id, newStatus) => {
        setTableLoading(true);

        BranchInventory.UpdateEndOfShiftReport(
            branchId,
            id,
            { status: newStatus },
            () => {
                notification.success({
                    message: 'Success',
                    description: `Report #${id} has been ${newStatus}.`,
                });
                loadReports();
            },
            () => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to update status',
                });
                setTableLoading(false);
            }
        );
    };

    // --------------------------------------------------
    // Table columns
    // --------------------------------------------------
    const columns = [
        {
            title: 'Issuer',
            dataIndex: 'userName',
            key: 'userName',
            //sorter: true,
            //sortOrder: sorter.field === 'user.name' && sorter.order,
        },
        {
            title: 'Date of Report',
            dataIndex: 'date',
            key: 'date',
            //sorter: true,
            //sortOrder: sorter.field === 'date' && sorter.order,
        },
        {
            title: 'Expire At',
            dataIndex: 'expiration_date',
            key: 'expiration_date',
            //sorter: true,
            //sortOrder: sorter.field === 'expiration_date' && sorter.order,
        },
        {
            title: 'Submitted At',
            dataIndex: 'createdAtFormatted',
            key: 'createdAtFormatted',
            //sorter: true,
            //sortOrder: sorter.field === 'created_at' && sorter.order,
        },
        {
            title: 'Shift',
            dataIndex: 'shift',
            key: 'shift',
        },
        {
            title: 'Notices',
            dataIndex: 'noticesSummary',
            key: 'noticesSummary',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                if (status === 'pending') return <Tag color="orange">Pending</Tag>;
                if (status === 'confirmed') return <Tag color="green">Confirmed</Tag>;
                if (status === 'rejected') return <Tag color="red">Rejected</Tag>;
                return status;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'actions',
            fixed: 'right',
            width: 180,
            render: (id, record) => (
                <div className="flex flex-col">
                    <div>
                        <Link to={`/dashboard/operations/daily-logs/end-of-shift/report/${id}`}>
                            <Button type="link">View</Button>
                        </Link>
                        {isManager && (
                            <Link
                                to={`/dashboard/operations/daily-logs/end-of-shift/${branchId}/report/${id}/edit`}
                            >
                                <Button type="link">Edit</Button>
                            </Link>
                        )}
                    </div>
                    {isManager && record.status === 'pending' && (
                        <div className="flex flex-row space-x-2">
                            <Button onClick={() => updateReportStatus(id, 'confirmed')}>
                                Confirm
                            </Button>
                            <Button danger onClick={() => updateReportStatus(id, 'rejected')}>
                                Reject
                            </Button>
                        </div>
                    )}
                </div>
            ),
        },
    ];

    // --------------------------------------------------
    // Render
    // --------------------------------------------------
    return (
        <ContainerWrapper>
            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <DefaultContainer>
                    <h2>End of Shift Reports</h2>

                    <Space style={{ marginBottom: 16 }}>
                        {/* Dropdown to filter by Notice User (Server-Side) */}
                        <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder="Filter by Notice User"
                            onChange={(value) => {
                                // Reset pagination to page 1
                                setPagination((prev) => ({ ...prev, current: 1 }));
                                setNoticeUserFilter(value || '');
                                updateURL({
                                    page: 1,
                                    pageSize: pagination.pageSize,
                                    sortField: sorter.field,
                                    sortOrder: sorter.order,
                                    notice_user_id: value,
                                });
                            }}
                            value={noticeUserFilter || undefined}
                            allowClear
                            style={{ width: 250 }}
                        >
                            {noticeUsers.map((userObj) => (
                                <Select.Option key={userObj.id} value={String(userObj.id)}>
                                    {userObj.name} ({userObj.count})
                                </Select.Option>
                            ))}
                        </Select>

                        {/* Clear Filter Button */}
                        <Button
                            onClick={() => {
                                setNoticeUserFilter('');
                                setPagination((prev) => ({ ...prev, current: 1 }));
                                updateURL({
                                    page: 1,
                                    pageSize: pagination.pageSize,
                                    sortField: sorter.field,
                                    sortOrder: sorter.order,
                                    notice_user_id: null,
                                });
                            }}
                        >
                            Clear Filter
                        </Button>
                    </Space>

                    <Table
                        columns={columns}
                        dataSource={reports}
                        rowKey="id"
                        pagination={false}
                        loading={tableLoading}
                        onChange={handleTableChange}
                        scroll={{ x: 1200 }}
                    />

                    <div className="mt-4 ml-4 w-full">
                        <Pagination
                            current={pagination.current}
                            pageSize={pagination.pageSize}
                            total={pagination.total}
                            onChange={handlePageChange}
                            onShowSizeChange={handlePerPageChange}
                            showSizeChanger
                            pageSizeOptions={['10', '20', '50']}
                            showTotal={(total) => `Total ${total} items`}
                            className="w-full"
                        />
                    </div>
                </DefaultContainer>
            </SkeletonWrapper>
        </ContainerWrapper>
    );
};

export default EndOfShiftReportsIndex;
