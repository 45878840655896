import React from 'react';

const FoodImage = ({image, height=null}) => {
    return (
        <div className="p-1 w-full flex flex-row items-center justify-center">
            <img src={image} style={{height: height?height:"auto", maxHeight: "32vw"}} />
        </div>
    );
};

export default FoodImage;