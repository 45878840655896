// UserNotices.jsx
import React, { useEffect, useState } from 'react';
import { notification } from 'antd';

import Profile from "../../../../http/Profile";
import DefaultContainer from "../../../../components/DefaultContainer";
import MenuSkeleton from "../../../../components/skeletons/MenuSkeleton";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import EndOfShiftNotice from "../Dashboard/Components/EndOfShiftNotice";

const UserNotices = () => {
    const [notices, setNotices] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadNotices();
    }, []);

    const loadNotices = () => {
        setLoading(true);
        // e.g. "Profile.Notices" returns all notices for the current user
        Profile.Notices(
            (response) => {
                const data = response.data.data.notices || [];
                setNotices(data);
                setLoading(false);
            },
            (err) => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to load notices.',
                });
                setLoading(false);
            }
        );
    };

    // Called by <EndOfShiftNotice> when a notice is acknowledged
    const handleAcknowledged = (noticeId, updatedNotice) => {
        setNotices((prev) => prev.map((n) => (n.id === noticeId ? updatedNotice : n)));
    };

    return (
        <DefaultContainer className="mx-auto max-w-8xl py-2 mt-4" append>
            <div className="text-xl font-bold pb-2">Notices & Warnings</div>
            <SkeletonWrapper loading={loading} skeleton={MenuSkeleton}>
                {notices && notices.length > 0 ? (
                    notices.map((notice) => (
                        <EndOfShiftNotice
                            key={notice.id}
                            notice={notice}
                            onAcknowledged={handleAcknowledged}
                        />
                    ))
                ) : (
                    <div>You have not received any notices.</div>
                )}
            </SkeletonWrapper>
        </DefaultContainer>
    );
};

export default UserNotices;
