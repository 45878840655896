import React, {useEffect, useState} from 'react';
import {notification} from 'antd';
import DefaultContainer from '../../../../../components/DefaultContainer';
import Profile from "../../../../../http/Profile";
import EndOfShiftNotice from "./EndOfShiftNotice";

const UnacknowledgedUserNotices = () => {

    const [notices, setNotices] = useState([]);

    useEffect(() => {
        loadNotices()
    }, []);

    const loadNotices = () => {
        Profile.UnacknowledgedNotices((response) => {
            setNotices(response.data.data.notices);
        }, (err) => {
            notification.error({
                message: 'Error',
                description: 'Failed to load notices.',
            });
        });
    };

    return (
        notices && notices.length > 0 ?
            <DefaultContainer>
                <div className={"text-xl font-bold pb-2"}>My Notices & Warnings</div>
                {notices.map(notice => {
                    return (<div className={"mb-3"}><EndOfShiftNotice notice={notice} onAcknowledged={loadNotices} /></div>)
                })}
            </DefaultContainer>
        :
            null
    )
};

export default UnacknowledgedUserNotices;
